import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import { formatCurrencyCLP } from '../helpers';
import './Products.css';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAngleDoubleRight, faHeart } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductosLoading from '../ProductosLoading/ProductosLoading';

import useSearchStore from "../searchStore";
import useProductsStore from '../productsStore';
import useConfigStore from '../configStore';

export default function Products({ productoSeleccionado, setProductoSeleccionado = () => { }, modalIsOpen, setModalIsOpen = () => { } }) {

    const search = useSearchStore((state) => state.search);
    const config = useConfigStore((state) => state.configData);

    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fixedCategories, setFixedCategories] = useState(false);
    const [cantidad, setCantidad] = useState(1);
    const categoriasRefs = useRef([]);
    const categoriasInputRef = useRef(null);
    const swiperRef = useRef(null);
    const [productos, setProductos] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('Todo');
    const [favoritos, setFavoritos] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [variantes, setVariantes] = useState([]);
    const [variantesSeleccionadas, setVariantesSeleccionadas] = useState({});
    const [obligatorio, setObligatorio] = useState(false);
    const [obligatorioLista, setObligatorioLista] = useState({});
    const [nuevoPrecio, setNuevoPrecio] = useState(0);

    const [carrito, setCarrito] = useState(false);
    const productsGlobal = useProductsStore((state) => state.productsSet);

    const handleClickCategoria = (categoria) => {
        setCategoriaSeleccionada(categoria);
    }


    // Obtener configuración inicial
    const obtenerConfiguracion = async () => {
        if (config) {
            setCarrito((config.carrito == '1' ? true : false));
        }

    };

    useEffect(() => {
        if (search) {
            openModal(search);
        } else {
            localStorage.removeItem('search');
        }
        window.addEventListener('scroll', handleScroll);

        obtenerConfiguracion();
        cargarFavoritos();
        cargarProductos();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [search, productsGlobal, config]);

    const handleScroll = () => {
        if (window.scrollY > categoriasInputRef.current.offsetTop) {
            setFixedCategories(true);
        } else {
            setFixedCategories(false);
        }
    };

    const cargarProductos = () => {

        const productosData = productsGlobal;
        if (productosData.length > 0) {

            const categoriasMap = new Map();
            productosData.forEach(producto => {
                const categoria = producto.categoria;
                if (categoriasMap.has(categoria)) {
                    categoriasMap.get(categoria).push(producto);
                } else {
                    categoriasMap.set(categoria, [producto]);
                }
            });
            const categoriasArray = Array.from(categoriasMap, ([categoria, productos]) => ({ categoria, productos }));
            setCategorias(categoriasArray);
            setLoading(false);
            setProductos(productosData);
        } else {
            return [];
        }
    };



    const obtenerImagen = (item, defaultImg = false) => {
        try {
            const imagenes = JSON.parse(item.imagenes);
            if (imagenes.urls && imagenes.urls.length > 0) {
                return imagenes.urls[0];
            }
        } catch (e) {
            console.error("Error al parsear las imágenes:", e);
        }
        if (defaultImg) {
            return '/imagenes_productos/no-image.jpg'
        }
        return null;
    };

    const obtenerImagenes = (item) => {
        let imagenes;
        try {
            imagenes = JSON.parse(item.imagenes);
        } catch (e) {
            console.error("Error parsing 'imagenes':", e);
            return [];
        }

        if (imagenes && imagenes.urls && imagenes.urls.length > 0) {
            return imagenes.urls;
        }
        return [];
    };



    const cargarFavoritos = () => {
        const storedFavoritos = JSON.parse(localStorage.getItem('favoritos')) || [];
        setFavoritos(storedFavoritos);
    };

    const addToCart = () => {
        if (productoSeleccionado) {
            const cart = JSON.parse(localStorage.getItem('cart')) || [];
            const existingItemIndex = cart.findIndex(item =>
                item.idProducto === productoSeleccionado.idProducto
            );

            cart.push({ idProducto: productoSeleccionado.idProducto, variante: [{ ...variantesSeleccionadas, cantidad, nuevoPrecio }], cantidad });


            localStorage.setItem('cart', JSON.stringify(cart));

            openModal({ ...productoSeleccionado, cantidad });
            closeModal();
            cargarProductos();
            setVariantes({});
            setVariantesSeleccionadas({});
            toast.success('Producto agregado');
        }
    };

    const incrementCantidad = () => {
        setCantidad(cantidad + 1);
    };

    const decrementCantidad = () => {
        if (cantidad > 1) {
            setCantidad(cantidad - 1);
        }
    };

    const agregarAFavoritos = (idProducto) => {
        const favList = [...favoritos];
        const index = favList.indexOf(idProducto);
        if (index === -1) {
            favList.push(idProducto);
            setFavoritos(favList);
            localStorage.setItem('favoritos', JSON.stringify(favList));
        } else {
            favList.splice(index, 1);
            setFavoritos(favList);
            localStorage.setItem('favoritos', JSON.stringify(favList));
        }
    };


    const openModal = (producto) => {
        setNuevoPrecio(0);
        setVariantesSeleccionadas([]);
        setProductoSeleccionado(producto);
        setVariantes(obtenerVariantes(producto));
        setModalIsOpen(true);
        cargarFavoritos();
    };

    const closeModal = () => {
        //localStorage  remover el item search por completo
        localStorage.removeItem('search');
        setModalIsOpen(false);
        setCantidad(1);
        setSelectedItem('');
    };



    const obtenerVariantes = (item) => {
        setObligatorio(false);
        let variantes;
        try {
            variantes = JSON.parse(item.variantes);
        } catch (e) {
            console.error("Error parsing 'variantes':", e);
            return {};
        }

        if (variantes && variantes.tipos) {
            let dataObligatorio = [];
            Object.keys(variantes.tipos).forEach(tipo => {
                if (!Array.isArray(variantes.tipos[tipo])) {
                    if (variantes.tipos[tipo].obligatorio) {
                        dataObligatorio.push(variantes.tipos[tipo].obligatorio)
                    }

                    if (variantes.tipos[tipo].obligatorio == true) {
                        setObligatorio(true);
                    }
                    return;
                }


                variantes.tipos[tipo] = {
                    multiple: false,
                    variantes: variantes.tipos[tipo]
                };
            });
            console.log("obliga ", dataObligatorio);
            setObligatorioLista(dataObligatorio);
            return variantes.tipos;
        }

        return {};
    };

    const handleCheckboxChange = (tipo, nombre, multiple, isObligatorio = false, tipoIndex, precio_variante = 0, precio_suma = 0) => {


        if (obligatorioLista.length > 0) {
            const listaObligatorios = obligatorioLista;
            listaObligatorios[tipoIndex] = !listaObligatorios[tipoIndex];
            setObligatorioLista(listaObligatorios);
            setObligatorio(obligatorioLista.includes(true));
            if (!obligatorioLista.includes(true)) {
                setObligatorioLista({});
            }

        }

        if (precio_variante > 0) {
            console.log(precio_variante)
            setNuevoPrecio(precio_variante);
        }
        setVariantesSeleccionadas((prevSelectedOptions) => {
            if (multiple) {
                const updatedOptions = prevSelectedOptions[tipo] || [];
                if (updatedOptions.includes(nombre)) {


                    return {
                        ...prevSelectedOptions,
                        [tipo]: updatedOptions.filter(option => option !== nombre),
                    };
                } else {
                    return {
                        ...prevSelectedOptions,
                        [tipo]: [...updatedOptions, nombre],
                    };
                }
            } else {

                return {
                    ...prevSelectedOptions,
                    [tipo]: [nombre],
                };
            }
        });
    }
    const resetCheckboxChange = () => {
        setVariantesSeleccionadas({});
        setNuevoPrecio(0);

    }

    return (
        <div className='ProductsContain'>
            <ToastContainer />
            <div className={`categoriasInputs ${fixedCategories ? 'fixed' : ''}`} ref={categoriasInputRef}>

                {categorias.length > 0 &&
                    <input
                        type="button"
                        value="Todo"
                        onClick={() => handleClickCategoria('Todo')}
                        className={categoriaSeleccionada === 'Todo' ? 'inputTodo' : 'inputTodoOff'}
                    />
                }


                {categorias.map(({ categoria }, index) => (
                    <input
                        key={categoria}
                        type="button"
                        value={categoria}
                        onClick={() => handleClickCategoria(categoria)}
                        categoria
                        className={categoriaSeleccionada === categoria ? ('inputTodo ' + 'categoria_'+index) : ('inputTodoOff ' + 'categoria_'+index)}

                    />
                ))}
            </div>
            <div>

                {loading ? (
                    <ProductosLoading />
                ) : (
                    <div >
                        {categoriaSeleccionada === 'Todo' && (
                            <div className='Products'>
                                {productos.some(item => item.masVendido === "si") && (
                                    <div className='categoriSection'>


                                        <Swiper
                                            effect={'coverflow'}
                                            grabCursor={true}
                                            slidesPerView={'auto'}
                                            id='swiper_container_products'
                                        >
                                            {productos.filter(item => item.masVendido === "si" && item.stock !== 0 && item.estado).map(item => (
                                                <SwiperSlide id='SwiperSlide-scroll-products-masvendidos' key={item.idProducto}>
                                                    <div className='cardProdcutmasVendido' onClick={() => {

                                                        openModal(item);
                                                    }}>
                                                        {(<img src={obtenerImagen(item, true)} alt="imagen" />)}
                                                        <h6 className='masVendido'>Más Vendido</h6>
                                                        <div className='cardTextDestacado'>
                                                            <h4 >{item.titulo}</h4>
                                                            <span >{item.descripcion}</span>
                                                            <div className='deFLexPrice etiquetaDestacado'>
                                                                {
                                                                    (item?.precio > 0 && item?.precio !== undefined) && (
                                                                        <>
                                                                            <h5> {formatCurrencyCLP(item?.precio)}</h5>
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    (item?.precioAnterior > 0 && item?.precioAnterior !== undefined) && (
                                                                        <h4 className='precioTachado'> {formatCurrencyCLP(item?.precioAnterior)}</h4>
                                                                    )
                                                                }


                                                            </div>


                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>

                                )}

                                {categorias.map(({ categoria, productos }, index) => (
                                    <div key={index} className='categoriSection' ref={ref => categoriasRefs.current[index] = ref}>

                                        <div className='deFlexTitlesection'>
                                            <h3 >{categoria}</h3>
                                            <button onClick={() => {
                                                handleClickCategoria(categoria);
                                                document.querySelector('.categoria_'+index).scrollIntoView({
                                                    behavior: 'smooth'
                                                }); 
                                            }}>
                                                Ver más
                                            </button>
                                        </div>

                                        <Swiper
                                            effect={'coverflow'}
                                            grabCursor={true}
                                            slidesPerView={'auto'}
                                            id='swiper_container_products'
                                        >
                                            {productos.map(item => (

                                                item.estado ? (
                                                    <SwiperSlide id='SwiperSlide-scroll-products' key={item.idProducto}>
                                                        <div className='cardProdcut' key={item.idProducto} onClick={() => {

                                                            openModal(item);
                                                        }}>
                                                            {obtenerImagen(item) && (<img src={obtenerImagen(item)} alt="imagen" />)}
                                                            <div className='cardText'>
                                                                <h4>{item.titulo}</h4>
                                                                <span>{item.descripcion}</span>
                                                                <div className='deFLexPrice precioCard'>
                                                                    {
                                                                        (item?.precio > 0 && item?.precio !== undefined) && (
                                                                            <>
                                                                                <h5> <strong> {
                                                                                    item?.stock === 0 ? ('No disponible') : formatCurrencyCLP(item?.precio)
                                                                                }</strong></h5>
                                                                                {
                                                                                    (item?.precioAnterior > 0 && item?.precioAnterior !== undefined) && (
                                                                                        <h4 className='precioTachado'> {formatCurrencyCLP(item?.precioAnterior)}</h4>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }   </div>
                                                                <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />

                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ) : ('')
                                            ))}
                                        </Swiper>
                                    </div>
                                ))}
                            </div>
                        )}


                        <div className='categoriSectionSelected'>
                            {productos
                                // Filtra los productos solo para la categoría seleccionada
                                .filter(item => categoriaSeleccionada !== 'Todo' && item.categoria === categoriaSeleccionada && item.estado)
                                // Mapea para renderizar los productos dentro de la categoría
                                .map(item => (
                                    <div className='cardProdcutSelected' onClick={() => openModal(item)}>
                                        {obtenerImagen(item) && (<img src={obtenerImagen(item)} alt="imagen" />)}
                                        <div className='cardTextSelected'>
                                            <h4>{item.titulo}</h4>
                                            <span>{item.descripcion}</span>
                                            {
                                                (item?.precio > 0 && item?.precio !== undefined) && (
                                                    <div className='deFLexPrice'>
                                                        <h5> <strong> {item?.stock === 0 ? ('No disponible') : formatCurrencyCLP(item?.precio)}</strong></h5>
                                                        {
                                                            (item?.precioAnterior > 0 && item?.precioAnterior !== undefined) && (
                                                                <h4 className='precioTachado'> {formatCurrencyCLP(item?.precioAnterior)}</h4>
                                                            )
                                                        }

                                                    </div>
                                                )
                                            }
                                            <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                        </div>
                                    </div>
                                ))}
                        </div>





                    </div>
                )}

            </div>
            <Modal isOpen={modalIsOpen}

                appElement={document.getElementById('app')}
                ariaHideApp={false}
                onRequestClose={closeModal}
                className="modal-detail"
                overlayClassName="overlay-detail">
                {productoSeleccionado && (
                    <div className='modal-content-detail'>
                        <div className='deFlexDetail'>

                            <button onClick={closeModal} className='back'> <FontAwesomeIcon icon={faArrowLeft} /></button>



                            <button onClick={() => agregarAFavoritos(productoSeleccionado.idProducto)} className={favoritos.includes(productoSeleccionado.idProducto) ? ' favoritos-btn  likeColor' : 'favoritos-btn unlike'}>


                                <FontAwesomeIcon icon={faHeart} />
                            </button>
                        </div>
                        {obtenerImagenes(productoSeleccionado).length > 0 ? (
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                effect={'coverflow'}
                                grabCursor={true}
                                loop={true}
                                slidesPerView={'auto'}
                                coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
                                navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                                autoplay={{ delay: 3000 }}
                                pagination={{ clickable: true }}
                                onSwiper={(swiper) => {
                                    swiperRef.current = swiper;
                                }}
                                id='swiper_container_Imgs'
                            >

                                {obtenerImagenes(productoSeleccionado).map((url, index) => (
                                    <SwiperSlide id='SwiperSlide-scroll-img' key={index}>
                                        <img src={url} alt={`Imagen ${index + 1}`} />
                                    </SwiperSlide>
                                ))}



                            </Swiper>) :
                            (

                                <p style={{ paddingTop: '70px' }}></p>
                            )}

                        <div className='modalText'>

                            <h2>{productoSeleccionado.titulo}
                                <sup className={favoritos.includes(productoSeleccionado.idProducto) ? 'textLike' : ''}>
                                    {favoritos.includes(productoSeleccionado.idProducto) ? 'Te gusta' : ''}</sup>


                            </h2>
                            {
                                productoSeleccionado?.stock === 0 && <p>(No disponible)</p>
                            }
                            <h4>  {productoSeleccionado.categoria}</h4>


                            <p>{productoSeleccionado.descripcion} </p>

                            <div className='deFLexPrice'>
                                {(productoSeleccionado?.precio > 0 && productoSeleccionado?.precio !== undefined) && (
                                    <>
                                        <h5>
                                            <strong> {formatCurrencyCLP(productoSeleccionado.precio)} </strong>
                                        </h5>

                                        {
                                            (productoSeleccionado?.precioAnterior > 0 && productoSeleccionado?.precioAnterior !== undefined) && (
                                                <h4 className='precioTachado'> {`${formatCurrencyCLP(productoSeleccionado?.precioAnterior)}`}</h4>
                                            )
                                        }



                                    </>
                                )
                                }
                            </div>


                            <div className='itemsDetail'>
                                {Object.keys(variantes).map((tipo, tipoIndex) => {
                                    let number = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
                                    const uniqueId = `switch-${number}`;
                                    const isMultiple = variantes[tipo].multiple;
                                    const isObligatorio = variantes[tipo].obligatorio;

                                    return (
                                        <fieldset key={tipoIndex}>
                                            <legend>{tipo}</legend>



                                            {variantes[tipo].variantes && variantes[tipo].variantes.map((variante, varianteIndex) => (
                                                <div key={varianteIndex}>
                                                    <label htmlFor={uniqueId + variante.nombre}><input
                                                        type={isMultiple ? "checkbox" : "radio"}
                                                        name={tipo}
                                                        onChange={() => handleCheckboxChange(tipo, variante.nombre, isMultiple, isObligatorio, tipoIndex, variante.precio_variante, variante.precio_suma)}
                                                        id={uniqueId + variante.nombre}
                                                        checked={variantesSeleccionadas[tipo] && variantesSeleccionadas[tipo].includes(variante.nombre)}
                                                    />
                                                        <span>{variante.nombre}  </span>
                                                        {(variante.precio_variante > 0 ? formatCurrencyCLP(variante.precio_variante) : ' ')}
                                                        {(variante.precio_suma > 0 ? '+' + formatCurrencyCLP(variante.precio_suma) : ' ')}
                                                    </label>
                                                </div>
                                            ))}

                                            <button className="btnReset" onClick={() => resetCheckboxChange()}>X</button>
                                        </fieldset>
                                    );
                                })}
                            </div>

                        </div>

                        <div className='deFlexGoTocart'>

                            {
                                carrito ? (
                                    productoSeleccionado?.precio > 0 && productoSeleccionado?.stock !== 0 ? (
                                        <>

                                            <div className='deFlexCart'>
                                                <button onClick={decrementCantidad}>-</button>
                                                <span>{cantidad}</span>
                                                <button onClick={incrementCantidad}>+</button>
                                            </div>
                                            <button onClick={addToCart} className={obligatorio ? 'btn-disabled' : 'btn'} disabled={obligatorio}>
                                                Agregar  (   {`${formatCurrencyCLP((nuevoPrecio > 0 ? nuevoPrecio : productoSeleccionado?.precio) * cantidad)}`} )</button>
                                        </>

                                    ) : (<button className="btn">No disponible</button>)
                                ) : (
                                    <button onClick={closeModal} className='btn'> <FontAwesomeIcon icon={faArrowLeft} /> Volver</button>
                                )


                            }

                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
}
