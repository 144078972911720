import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link as Anchor } from 'react-router-dom'; 
import baseURL from '../url';
import Logo from '../logo';
import 'swiper/swiper-bundle.css'; 
import Profile from '../Profile/Profile'; 
import './Navbar.css'; 
import InputSerach from '../InputSearch/InputSearchs'; 
import useBannersStore from '../bannersStore';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const banners = useBannersStore(state => state.bannersData)

    useEffect(() => {
        cargarBanners();
    }, [banners]);

    const cargarBanners = () => { 

        if(banners){
                const data = banners;
                const bannerImages = data.banner.map(banner => banner.imagen);
                setImages(bannerImages);
                setLoading(false); 

        }
    };

    const handleCloseModal = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsOpen(false);
            setIsClosing(false);
        }, 500); // Duración de la animación de salida
    };

    return (
        <header>
            <nav>
                <Anchor to={`/`} className='logo'>
                <Logo /></Anchor>
                <div className='deFLexNavs'> 
                    <InputSerach /> 
                    <div className={`nav_toggle ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <Modal
                    isOpen={isOpen}
                    onRequestClose={handleCloseModal}
                    className={`modalNav ${isClosing ? 'modalNav-close' : ''}`}
                    overlayClassName="overlay"
                >
                    <div className="modalNav-content">
                        {loading ? (
                            <div className='loadingBannerFondo'></div>
                        ) : (
                            <>
                                {images.length > 0 && (
                                   <div className='fondo'>
                                    <img src={images[0]} alt={`imagen`} />
                                </div> 
                                )}
                                
                                <Profile />
                            </>
                        )}
                    </div>
                </Modal>
            </nav>
        </header>
    );
}
