import React, { useEffect, useState } from 'react'; 
import baseURL from './url';
import logoStore from './logoStore';

export default function Logo() {
    const [logoUrl, setLogoUrl] = useState(null);
    const logoData = logoStore((state) => state.logoData)

    useEffect(() => {
        // Función para obtener el logo desde la API
        const fetchLogo = async () => {  
                setLogoUrl(logoData);
             
        };

        fetchLogo();
    }, [logoData]);

    return (
        logoUrl && <img src={baseURL+logoUrl || 'https://www.cbvj.org.br/index/wp-content/uploads/2017/10/default-logo.png'} alt="logo" style={{maxWidth: '200px'}} /> 
    );
}