import React, { useState, useEffect } from 'react';
import Nabvar from '../Components/Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import Footer from '../Components/Footer/Footer';
import BtnWhatsapp from '../Components/BtnWhatsapp/BtnWhatsapp';
import NavbarMobile from '../Components/NavbarMobile/NavbarMobile';  
export default function IndexLayout() { 
    useEffect(() => {
        const link = document.createElement('link');
        link.href = '/tema.css?v='+Math.random(10);
        link.rel = 'stylesheet';
        document.head.appendChild(link); 
        // Cleanup function to remove the link when the component is unmounted
        return () => {
          document.head.removeChild(link);
        };
      }, []);
    const isScreenLarge = useMediaQuery('(min-width: 900px)');
    return (
        <div >
            {isScreenLarge ?
                <> 
                    <Nabvar />
                    <Outlet />
                    <Footer /> 
                    <NavbarMobile />
                    <BtnWhatsapp />
                </> :
                <>
 
                    <Outlet />
                    <Footer /> 
 
                </>}

            <div className='espaciobg2'> 
            </div>

        </div>
    );
}
