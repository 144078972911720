import React, { useState, useEffect } from 'react';
import Banners from '../../Components/Banners/Banners'
import Products from '../../Components/Products/Products'
import './Demo.css'
import Footer from '../../Components/Footer/Footer'
import BtnWhatsapp from '../../Components/BtnWhatsapp/BtnWhatsapp'
import Cart from '../../Components/Cart/Cart'
import NavbarMobile from '../../Components/NavbarMobile/NavbarMobile'
import { useMediaQuery } from '@react-hook/media-query';
import { SearchProvider } from '../../Components/GlobalContext';
import baseURL from '../../Components/url';
import ColorUpdater from "../../Components/ColorUpdater";
import { ToastContainer, toast } from 'react-toastify';
import useProductsStore from '../../Components/productsStore';
import useConfigStore from '../../Components/configStore';
import useContactoStore from '../../Components/contactoStore';
import useBannersStore from '../../Components/bannersStore';
import logoStore from '../../Components/logoStore';

import axios from 'axios';
export default function Demo() {

    const updateBanners = useBannersStore((state) => state.updateBanners);
    const updateLogo = logoStore((state) => state.updateLogo);

    const updateProducts = useProductsStore((state) => state.updateProducts);
    const updateConfig = useConfigStore((state) => state.updateConfig);
    const updateContacto = useContactoStore((state) => state.updateContacto);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [productoSeleccionado, setProductoSeleccionado] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [carrito, setCarrito] = useState(false);


    // Obtener configuración inicial
    const fetchLogo = async () => {
        try {
            const response = await fetch(`${baseURL}/logoGet.php`);
            const data = await response.json();
            if (data) { 
                updateLogo(data.imagen);
            }

        } catch (error) {
            console.error('Error:', error);
            toast.error('Error al obtener la configuración. Por favor, inténtelo de nuevo.');
        }
    };
  


    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {  
                updateContacto(data.contacto);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };


    // Obtener configuración inicial
    const obtenerConfiguracion = async () => {
        try {
            const response = await fetch(`${baseURL}/configuracionesGet.php`);
            const data = await response.json();
            if (data) {
                updateConfig(data);
                setCarrito((data.carrito == '1' ? true : false));
            }

        } catch (error) {
            console.error('Error:', error);
            toast.error('Error al obtener la configuración. Por favor, inténtelo de nuevo.');
        }
    };


    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                updateProducts((data.productos || []).filter(producto => producto.estado === 1));
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const cargarBanners = () => {
        fetch(`${baseURL}/bannersGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                updateBanners(data);
            })
            .catch(error => {
                console.error('Error al cargar productos:', error);
            });
    };

    useEffect(() => {
        cargarProductos();
        obtenerConfiguracion();
        fetchLogo();
    }, []);



    useEffect(() => {
        cargarContacto();
        cargarBanners();
    }, []);


    const isScreenLarge = useMediaQuery('(min-width: 900px)');
    return (
        <SearchProvider>
            <section className='demo'>


                <ColorUpdater />
                <Banners />
                <Products productoSeleccionado={productoSeleccionado} setProductoSeleccionado={setProductoSeleccionado} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                <Footer />
                {
                    carrito ?? (<Cart />)
                }
                <NavbarMobile
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setProductoSeleccionado={setProductoSeleccionado}
                    setModalIsOpenProducto={setModalIsOpen} />

                {isScreenLarge ?
                    <>
                        <BtnWhatsapp />
                    </> :
                    <>

                    </>}
            </section>
        </SearchProvider>
    )
}
